<template>
  <div class="top-movies">
    <div class="top-movies__element" v-for="(movie, index) in movies.slice(0, MAX_DISPLAY_NUM + 1)" :key="movie.mvid + index">
      <router-link v-if="index < MAX_DISPLAY_NUM"
                   class="top-movies__element__thumbnail" :to="{ name: 'movie', params: { prid: movie.prid, mvid: movie.mvid } }">
        <v-img class="top-movies__element__thumbnail__img" :src="thumbnail(movie.mvid)" :aspect-ratio="16/9" contain />
      </router-link>
      <router-link v-else
                   class="top-movies__element__thumbnail" :to="{ name: 'project', params: { prid: movie.prid }}">
        <div class="top-movies__element__thumbnail__text">もっと見る</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 表示するおすすめ動画の配列
    // 例: [{ mvid: 動画ID, prid: 企画ID }, ...}]
    movies: {
      type: Array,
      required: true
    }
  },
  mounted () {
  },
  computed: {
    /**
     * @return {Number} 動画の最大表示数
     */
    MAX_DISPLAY_NUM () {
      return 6
    },
    /**
     * @param {String} prid 企画ID
     * @return {Object} 企画情報
     */
    project () {
      return prid => {
        return this.$store.getters['projects/project'](prid)
      }
    },
    /**
     * @param {String} url vimeoのurl
     * @return {Object} vimeoの動画情報
     */
    vimeo () {
      return url => {
        return this.$store.getters['vimeos/vimeo'](url)
      }
    },
    /**
     * @return {String} 動画サムネイルのURL
     */
    thumbnail () {
      return mvid => {
        const movie = this.$store.getters['movies/movie'](mvid)
        const url = movie ? movie.movieURL : ''
        return this.vimeo(url) ? this.vimeo(url).thumbnail_url : ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.top-movies {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &__element {
    display: flex;
    flex-direction: column;
    align-items: top;
    margin-right: $unit_size*2;
    &:last-of-type {
      margin-right: 0;
    }
    &__thumbnail {
      display: inline-block;
      width: 250px;
      height: 140px;
      &__img {
        width: 100%;
        height: 100%;
      }
      &__text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 1.8rem;
        font-weight: 700;
        color: $concept_normal_color;
        border: 3px solid $concept_normal_color;
        border-radius: 8px;
      }
    }
  }
}
</style>


