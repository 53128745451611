<template>
  <header :class="isBlackBackground ? 'header--black' : 'header'" v-if="isDisplay && pageName">
    <v-btn text :class="isBlackBackground ? 'header__back--black' : 'header__back'" v-if="type.includes('back')" @click="back()">
      <v-icon class="header__back__icon">arrow_back</v-icon>
    </v-btn>
    <v-img class="header__logo" v-if="type.includes('logo')" :src="require('@/assets/img/logo.png')"
           @click="$router.push({ name: 'top' })" contain />
    <p class="header__title" v-if="type.includes('title') || type.includes('dynamic_title')">{{ title }}</p>
    <v-btn :class="isBlackBackground ? 'header__btn--black' : 'header__btn'" v-if="type.includes('menu')" text
           @click="pageName === 'menu' ? back() : $router.push({ name: 'menu' })">
      <v-icon class="header__btn__icon">{{ pageName === 'menu' ? 'close' : 'menu' }}</v-icon>
    </v-btn>
    <v-btn class="header__btn" v-if="type.includes('share')" text @click="share()">
      <v-icon class="header__btn__icon--share">share</v-icon>
    </v-btn>
  </header>
</template>

<script>
// ヘッダーを表示させないページ名
const NOT_DISPLAY = [
  'error',
  'notfound',
  'notsupported',
  'login'
]

// ヘッダーの詳細
// type: back, logo, title, dynamic_title, menu, share
const HEADER_DETAIL = {
  top: {
    type: ['logo', 'menu']
  },
  signup: {
    type: ['back', 'logo', 'menu']
  },
  signup_card: {
    type: ['back', 'logo', 'menu']
  },
  event_detail: {
    type: ['back', 'logo', 'menu']
  },
  guest_list: {
    type: ['back', 'logo', 'menu']
  },
  event_list: {
    type: ['back', 'logo', 'menu']
  },
  movie: {
    type: ['back', 'logo', 'menu']
  },
  movie_ogp: {
    type: ['back', 'share']
  },
  movie_list: {
    type: ['back', 'title', 'menu'],
    title: '動画一覧'
  },
  save_list: {
    type: ['back', 'logo', 'menu']
  },
  project: {
    type: ['back', 'logo', 'menu']
  },
  project_ogp: {
    type: ['back', 'logo', 'menu']
  },
  project_list: {
    type: ['back', 'title', 'menu'],
    title: '企画一覧'
  },
  profile: {
    type: ['back', 'title', 'menu'],
    title: 'プロフィール'
  },
  payment_card: {
    type: ['back', 'logo', 'menu']
  },
  menu: {
    type: ['logo', 'menu']
  },
  cancel: {
    type: ['back', 'title', 'menu'],
    title: '退会'
  },
  terms: {
    type: ['title', 'back'],
    title: '利用規約'
  },
  policy: {
    type: ['title', 'back'],
    title: 'プライバシーポリシー'
  },
  law: {
    type: ['title', 'back'],
    title: '特定商取引法に基づく表記'
  }
}

export default {
  computed: {
    /**
     * @return {Boolean} ヘッダーを表示させるかどうか
     */
    isDisplay () {
      return !NOT_DISPLAY.includes(this.$route.name)
    },
    /**
     * @return {String} router.jsで定義された名前
     */
    pageName () {
      return this.$route.name
    },
    /**
     * @return {Boolean} 背景書が黒か
     */
    isBlackBackground () {
      const targets = ['signup', 'signup_card', 'payment_card']
      return targets.includes(this.pageName)
    },
    /**
     * @return {String} ヘッダーのタイトル
     */
    title () {
      return this.type.includes('title') ? HEADER_DETAIL[this.pageName].title :
        this.type.includes('dynamic_title') ? this.$store.getters.dynamicTitle :
          ''
    },
    /**
     * @return {String[]} ヘッダーのタイプ
     */
    type () {
      return HEADER_DETAIL[this.pageName].type ? HEADER_DETAIL[this.pageName].type : []
    }
  },
  methods: {
    /**
     * 前のページに戻る
     * 直リンクなどで前のページが存在しない場合はホームへ遷移させる
     */
    back () {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: 'top' })
      }
    },
    /**
     * 動画ページのコピー
     */
    async share () {
      const title = document.title
      // LINEで外部ブラウザを開くパラメータを付与する
      const url = `${window.location.protocol}//${window.location.hostname}${window.location.pathname}?openExternalBrowser=1`

      // Web Share APIが利用できる場合とできない場合に分けて対応
      const isAvailable = !!navigator.share
      isAvailable ? await navigator.share({ title: title, url: url }) : await navigator.clipboard.writeText(url)

      // Web Share APIが利用できない場合はテロップを表示
      if (!isAvailable) this.$store.commit('setTelop', { show: true, msg: 'URLをコピーしました', type: 'success' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.header {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: $header_zindex;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  max-width: $max_width;
  height: $header_height;
  text-align: center;
  background-color: $white_normal_color;
  transform: translate(-50%, 0);
  &--black {
    @extend .header;
    background-color: $black_normal_color;
  }
  &__back {
    position: absolute;
    top: 0;
    bottom: 0;
    left: $unit_size*2;
    margin: auto;
    &--black {
      @extend .header__back;
      color: $white_normal_color !important;
    }
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: auto;
      height: 20px;
      padding: 0;
      color: $black_lighten_color;
    }
    &__icon {
      width: 20px;
      height: 20px;
      &.v-icon {
        font-size: 2rem;
      }
    }
  }
  &__logo {
    width: 160px;
    max-height: calc(#{$header_height} - 10px);
    margin: 0 auto;
  }
  &__title {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
  &__btn {
    position: absolute;
    top: 0;
    right: $unit_size*2;
    bottom: 0;
    margin: auto;
    &--black {
      @extend .header__btn;
      color: $white_normal_color !important;
    }
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: auto;
      height: 30px;
      padding: 0;
      color: $black_lighten_color;
    }
    &__icon {
      width: 30px;
      height: 30px;
      &.v-icon {
        font-size: 3rem;
      }
      &--share {
        width: 20px;
        height: 20px;
        &.v-icon {
          font-size: 2rem;
        }
      }
    }
  }
}
</style>
