import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // イベントの一覧
    // { evid: { name, description, ... }, evid: { ... }, ... }
    events: {},
    // これから開催される(開催中を含む)イベントID一覧 (現在時刻から近い順)
    planedEventIDs: [],
    // 最新の全てのイベント
    latestAllEvents: [],
    // 全イベントを取得済みかどうか
    isGotAllEvents: false
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String[]} これから開催される(開催中を含む)イベントID一覧 (現在時刻から近い順)
   */
  planedEventIDs: state => state.planedEventIDs,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} prid イベントID
   * @return {Object} イベント情報
   */
  event: state => evid => state.events[evid] || null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 最新の動画一覧
   */
  latestAllEvents: state => state.latestAllEvents,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 全イベントを取得済みかどうか
   */
  isGotAllEvents: state => state.isGotAllEvents

}

const mutations = {
  /**
   * イベント情報を追加する
   *
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} evid イベントID
   * @param {Object} event イベント
   */
  setEvent: (state, { evid, event }) => {
    state.events = Object.assign({}, state.events, { [evid]: event })
  },
  /**
   * これから開催されるイベンIDの一覧をセットする
   *
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String[]} planedEventIDs これから開催されるイベントID一覧 (現在時刻から近い順)
   */
  setPlanedEventIDs: (state, planedEventIDs) => {
    state.planedEventIDs = planedEventIDs
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  },
  /**
   * 全イベント情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} event イベント情報
   */
  pushLatestAllEvents: (state, event) => {
    state.latestAllEvents.push(event)
  },
  /**
   * 全イベント情報の取得状況をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Boolean} isGotAllEvents 全ての動画を取得済みかどうか
   */
  setIsGotAllEvents: (state, isGotAllEvents) => {
    state.isGotAllEvents = isGotAllEvents
  }
}

const actions = {
  /**
   * これから開催される(開催中を含む)イベント一覧を現在時刻から近い順に取得する
   *
   * @param {Date} nowAt 現在時刻
   */
  getPlanedEvents: async ({ commit }, nowAt) => {
    try {
      const snapshot = await firestore
        .collection('events')
        .where('isDisplayed', '==', true)
        .orderBy('endAt', 'asc')
        .startAfter(nowAt)
        .get()

      if (!snapshot.empty) {
        const planedEventIDs = []
        snapshot.docs.forEach(doc => {
          commit('setEvent', { evid: doc.id, event: doc.data() })
          planedEventIDs.push(doc.id)
        })
        commit('setPlanedEventIDs', planedEventIDs)
      }
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 指定したIDのイベント情報を取得する
   *
   * @param {String} evid イベントID
   */
  getEvent: async ({ commit }, evid) => {
    try {
      const doc = await firestore
        .collection('events')
        .doc(evid)
        .get()

      if (doc.exists) {
        commit('setEvent', { evid: doc.id, event: Object.assign(doc.data(), { evid: doc.id }) })
      }
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 最新イベント情報を取得
   * @param {Date} endAt 指定時刻に終わっていないイベントを取得
   * @return {Object[]} 取得した動画一覧
   */
  getLatestAllEvents: async ({ commit }, endAt) => {
    try {
      let isGodEvent = false
      const snapshot = await firestore
        .collection('events')
        .where('isDisplayed', '==', true)
        .orderBy('endAt', 'asc')
        .startAfter(endAt)
        .limit(10)
        .get()

      snapshot.docs.forEach(doc => {
        if (state.latestAllEvents.includes(doc.id)) {
          return
        }
        commit('setEvent', { evid: doc.id, event: doc.data() })
        commit('pushLatestAllEvents', doc.id)
        isGodEvent = true
      })
      if (!isGodEvent) {
        commit('setIsGotAllEvents', true)
      }
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
