<template>
  <div :class="'more--' + position">
    <router-link class="more__link" :to="to">
      <p class="more__link__text">{{ text }}</p>
      <v-icon class="more__link__icon">mdi-chevron-right</v-icon>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * リンクを寄せる位置(left, right, center)
     */
    position: {
      type: String,
      required: true,
      validator (val) {
        return ['left', 'right', 'center'].includes(val)
      }
    },
    /**
     * 表示するテキストの内容
     */
    text: {
      type: String,
      required: true
    },
    /**
     * 遷移先の情報
     * routerの引数の形で記載する
     */
    to: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.more {
  &--left {
    @extend .more;
    text-align: left;
  }
  &--right {
    @extend .more;
    text-align: right;
  }
  &--center {
    @extend .more;
    text-align: center;
  }
  &__link {
    display: inline-flex;
    align-items: center;
    &__text {
      margin-bottom: 0;
      font-size: 1.3rem;
    }
    &__icon {
      &.v-icon {
        font-size: 3rem;
      }
    }
  }
}
</style>
