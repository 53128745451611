<template>
  <v-app id="app" :class="isBlackBackground ? 'footballcoach-video--black' : 'footballcoach-video'">
    <original-header v-if="!isAuthProcessing" />
    <router-view class="footballcoach-video__main" v-if="!isAuthProcessing" v-show="!isSubmitting" />
    <original-footer v-if="!isAuthProcessing" />
    <processing v-if="isProcessing || isSubmitting" />
    <telop v-if="telop.show" />
  </v-app>
</template>

<script>
import OriginalHeader from '@/components/common/header.vue'
import OriginalFooter from '@/components/common/footer.vue'
import Processing from '@/components/common/processing.vue'
import Telop from '@/components/common/telop'

export default {
  components: { OriginalHeader, OriginalFooter, Processing, Telop },
  async mounted () {
    // authの監視
    this.$store.dispatch('auth/onAuth')
  },
  computed: {
    /**
     * @return {Boolean} 初期処理中かどうか
     */
    isAuthProcessing () {
      return this.$store.getters.isAuthProcessing
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Boolean} フォーム等で情報を更新中かどうか
     */
    isSubmitting () {
      return this.$store.getters.isSubmitting
    },
    /**
     * @return {Object} テロップの情報
     */
    telop () {
      return this.$store.getters.telop
    },
    /**
     * @return {String} router.jsで定義された名前
     */
    pageName () {
      return this.$route.name
    },
    /**
     * @return {Boolean} 背景書が黒か
     */
    isBlackBackground () {
      const targets = ['signup', 'signup_card', 'payment_card']
      return targets.includes(this.pageName)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

html {
  font-size: 10px !important;
}

#app {
  font-family: Roboto, 'Noto Sans JP', Avenir, Helvetica, Arial, sans-serif;
  color: $black_lighten_color;
  background-color: $white_normal_color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $black_lighten_color !important;
  text-decoration: none;
  outline: none;
}

.textlink {
  color: $green_normal_color !important;
  text-decoration: underline !important;
}

.footballcoach-video {
  width: 100vw;
  &--black {
    @extend .footballcoach-video;
    background-color: $black_normal_color !important;
  }
  & > .v-application--wrap {
    display: flex;
    flex-flow: row wrap;
    min-height: 100vh;
    text-align: justify;
  }
  &__main {
    width: 100%;
  }
  &__footer {
    align-self: flex-end;
  }
}
</style>
