import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    payment: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 支払い情報
   */
  payment: state => state.payment
}

const mutations = {
  /**
   * 支払い情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payment 支払い情報のオブジェクト
   */
  setPayment: (state, payment) => {
    state.payment = payment
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 支払い情報の監視
   * @param {String} uid ユーザーID
   */
  onPayment ({ commit, dispatch, state, rootGetters }, uid) {
    firestore.collection('payments').doc(uid).onSnapshot(async doc => {
      // 支払い情報の更新
      const payment = doc.exists ? doc.data() : null
      commit('setPayment', payment)

      // 支払い情報のステータスによる処理の実施
      if (payment && payment.status === 'cardError') {
        // カード変更時にカード利用枠の上限超えなどでStripeのカード情報更新のエラーが発生した場合
        commit('setTelop', { show: true, msg: 'カード情報に不備があります。\n利用可能なカード情報を入力ください。', type: 'error' }, { root: true })
      }
    }, error => {
      // ログアウトするとrulesで権限エラーが発生し、自動でリスナーをデタッチする仕様となっている
      // それ以外のエラーが出た場合はエラー画面に飛ばす
      if (error.code !== 'permission-denied') {
        router.push({ name: 'error' })
      }
    })
  },
  /**
   * 支払い情報の更新
   * @param {Object} payload 引数
   * @param {String} payload.uid ユーザーID
   * @param {Object} payload.params 更新パラメータ
   */
  updatePayment: async ({ commit }, payload) => {
    try {
      await firestore
        .collection('payments')
        .doc(payload.uid)
        .update(payload.params)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
