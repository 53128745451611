import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    subscription: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} サブスクリプション情報
   */
  subscription: state => state.subscription
}

const mutations = {
  /**
   * サブスクリプション情報のセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} subscription サブスクリプション情報
   */
  setSubscription: (state, subscription) => {
    state.subscription = subscription
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * サブスクリプション情報の監視
   * @param {String} uid ユーザーID
   */
  onSubscription ({ commit, dispatch, state, rootGetters }, uid) {
    firestore.collection('payments').doc(uid).collection('subscriptions')
      .orderBy('completedAt', 'desc').limit(1).onSnapshot(querySnapshot => {
        // 新規追加に対してのみ処理を実行
        let subscription = null
        for (const change of querySnapshot.docChanges()) {
          if (change.type === 'added') subscription = change.doc.data()
        }

        // 新しいサブスクリプションの格納
        commit('setSubscription', subscription)

        // 新規追加がない場合は処理終了
        if (!subscription) return

        // サブスクリプションのステータスによって処理を分岐
        if (['incomplete', 'incomplete_expired', 'past_due', 'unpaid'].includes(subscription.status)) {
          // 支払いに失敗しているのでカード変更画面に遷移
          router.push({ name: 'menu' })
          commit('setTelop', { show: true, msg: '決済が失敗しています。\nカード情報を更新してください。', type: 'error' }, { root: true })
        }
      }, error => {
        // ログアウトするとrulesで権限エラーが発生し、自動でリスナーをデタッチする仕様となっている
        // それ以外のエラーが出た場合はエラー画面に飛ばす
        if (error.code !== 'permission-denied') {
          router.push({ name: 'error' })
        }
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
