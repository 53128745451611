import { firebase, provider } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    uid: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String} ユーザーID
   */
  uid: state => state.uid
}

const mutations = {
  /**
    * ユーザーIDをstateにセット
    * @param {Object} state 暗黙的に受け取るstate
    * @param {String} uid ユーザーID
    */
  setUID: (state, uid) => {
    state.uid = uid
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
    * ユーザ情報が更新時、uidを更新する
    */
  onAuth ({ commit, getters, rootGetters, dispatch }) {
    const unsubscribe = firebase.auth().onAuthStateChanged(async auth => {
      commit('setAuthProcessing', true, { root: true })

      // auth情報をセット
      commit('setUID', auth ? auth.uid : null)

      // 遷移用のパラメータセット
      const query = { auth: new Date().getTime() }

      // 認証状況に応じた処理
      if (auth) {
        // ユーザー、例外、カード情報を取得
        const promises = []
        promises.push(dispatch('users/getUser', auth.uid, { root: true }))
        promises.push(dispatch('exemptions/getExemption', auth.uid, { root: true }))
        promises.push(dispatch('cards/getCard', auth.uid, { root: true }))
        const [user, exemption, card] = await Promise.all(promises)

        // アカウント作成で必要な情報の取得
        const isSignupUser = sessionStorage.getItem('isSignupUser')
        const isSignupCard = user && (!exemption && !card)

        if (isSignupUser) { // 初回サインアップの場合
          if (user) {
            // ユーザー情報が既にある場合
            await dispatch('signout')
            commit('setTelop', { show: true, msg: 'アカウントが存在します\nログインしてください', type: 'warning' }, { root: true })
            commit('setRedirectURL', '/', { root: true })
          } else {
            await dispatch('signup', auth.uid)
            commit('setRedirectURL', '/signup/card', { root: true })
          }

          // セッション情報の削除
          sessionStorage.removeItem('isSignupUser')
        } else if (isSignupCard) { // 支払い情報未登録の場合
          commit('setRedirectURL', '/signup/card', { root: true })
        } else { // 通常のアクセスの場合
          if (!user) {
            // ユーザー情報がない場合
            await dispatch('signout')
            commit('setTelop', { show: true, msg: 'アカウントが存在しません\n別のアカウントでログインするか入会申し込みをお願いします', type: 'warning' }, { root: true })
            commit('setRedirectURL', '/', { root: true })
          } else if (user.isDeleted) {
            // 退会済みの場合
            await dispatch('signout')
            commit('setTelop', { show: true, msg: 'このアカウントは退会済みです。', type: 'error' }, { root: true })
            commit('setRedirectURL', '/', { root: true })
          } else {
            // それ以外の場合
            const result = await firebase.auth().getRedirectResult()
            const isLoggedin = !!result.user

            // ログイン前に閲覧していた動画の履歴を取得する
            const guestBrowsingHistory = isLoggedin ? sessionStorage.getItem('guestBrowsingHistory') || '' : ''
            if (isLoggedin) {
              // ログイン認証の場合
              if (user && guestBrowsingHistory) {
                // 未ログイン時に閲覧していた動画がある場合続きから再生するために履歴を作成(更新)する
                const browsingHistory = JSON.parse(guestBrowsingHistory)
                await putBrowsingHistory(dispatch, rootGetters, {
                  uid: auth.uid,
                  prid: browsingHistory.prid,
                  mvid: browsingHistory.mvid,
                  time: browsingHistory.time
                })

                commit('setTelop', { show: true, msg: 'ログインしました', type: 'success' }, { root: true })

                // ログイン前に閲覧していた動画ページに遷移する
                commit('setRedirectURL', `/movie/${browsingHistory.prid}/${browsingHistory.mvid}`, { root: true })
              } else if (user) {
                commit('setTelop', { show: true, msg: 'ログインしました', type: 'success' }, { root: true })

                // ページ遷移先の設定
                commit('setRedirectURL', '/', { root: true })
              } else {
                commit('setTelop', { show: true, msg: 'アカウントが存在しません\nアカウントを作成してください。', type: 'error' }, { root: true })

                // ページ遷移先の設定
                commit('setRedirectURL', '/menu', { root: true })
              }
            }
            sessionStorage.removeItem('guestBrowsingHistory')
          }
        }

        // ログアウトしていなければ監視系の処理を実行
        if (firebase.auth().currentUser) {
          // 支払い情報とサブスクリプション情報の監視を開始
          dispatch('payments/onPayment', auth.uid, { root: true })
          dispatch('subscriptions/onSubscription', auth.uid, { root: true })
        }
      }

      const redirectPath = rootGetters.redirectPath
      const queryParameter = rootGetters.queryParameter

      // 元々あったパラメータを消さないように再セット
      queryParameter.substr(1).split('&').forEach(params => {
        const param = params.split('=')
        if (param[0] && param[0] !== 'auth') query[param[0]] = decodeURIComponent(param[1])
      })

      commit('setAuthProcessing', false, { root: true })
      router.replace({ path: redirectPath, query: query })
      unsubscribe()
    })
  },
  /**
   * サインアップ周りの処理
   * @param {String} uid サインアップするユーザーのID
   */
  signup: async ({ commit, dispatch }, uid) => {
    try {
      // ユーザー情報の作成
      await dispatch('users/setUser', { uid: uid, params: {
        authority: 'member',
        createdAt: new Date(),
        updatedAt: new Date(),
        isDeleted: false
      } }, { root: true })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * サインイン
   * @param {String} sns SNS認証のプロバイダー名 google, facebook, twitter
   */
  signin: async ({ commit, rootGetters }, sns) => {
    try {
      // ログイン処理が実施可能なブラウザかをチェックする
      const isAvailable = availableLoginBrowser(commit, rootGetters)

      if (isAvailable) {
        await firebase.auth().signInWithRedirect(provider[sns])
      }
    } catch {
      // エラーの場合はエラー画面に遷移させる
      router.push({ name: 'error' })
    }
  },
  /**
   * サインアウト
   */
  signout: async ({ commit, dispatch }) => {
    try {
      await firebase.auth().signOut()

      // storeをリセットする
      dispatch('resetState', null, { root: true })
    } catch {
      // エラーの場合はエラー画面に遷移させる
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

/**
 * ログイン実施可能ブラウザかどうかの判定を実施する
 * WebView(LINE/Facebook/Yahooのアプリ内ブラウザ)のときはサポート対象外ページに遷移させる
 * @see "https://docs.google.com/document/d/1WITn598mniIV7k2ZmBJT8wLO6vOhI41tXp8GC-Qihg0"
 * @param {Function} commit store更新のためのメソッド
 * @param {Object} rootGetters state内のすべてのgetter
 * @return {Boolean} ログイン実施可能なブラウザかどうか
 */
const availableLoginBrowser = (commit, rootGetters) => {
  const path = window.location.pathname || '/'
  if (!rootGetters.isSupportBrowser) {
    commit('setReferrerPath', path, { root: true })
    router.push({ name: 'notsupported' })
    return false
  } else {
    return true
  }
}

/**
 * 未ログイン時に閲覧していた動画の閲覧履歴を保存or更新する
 * @param {Function} dispatch 他のアクションを実行するメソッド
 * @param {Object} rootGetters state内のすべてのgetter
 * @param {Object} history 履歴 { uid: 閲覧したユーザーのID, prid: 企画ID, mvid: 動画ID, time: 最終閲覧時間 }
 */
const putBrowsingHistory = async (dispatch, rootGetters, history) => {
  // すでに履歴が存在しているかどうかをチェックする
  await dispatch('browsingHistories/getHistory', {
    uid: history.uid,
    prid: history.prid,
    mvid: history.mvid
  }, { root: true })

  // 存在している場合は更新, 存在していない場合は追加
  if (rootGetters['browsingHistories/history']({ prid: history.prid, mvid: history.mvid })) {
    await dispatch('browsingHistories/updateHistory', history, { root: true })
  } else {
    await dispatch('browsingHistories/setHistory', history, { root: true })
  }
}
