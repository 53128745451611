import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 動画の一覧
    // { mvid: Object, mvid: Object, ... }
    movies: {},
    // 最新の全ての動画
    latestAllMovies: [],
    // 全動画を取得済みかどうか
    isGotAllMovies: false,
    // 最新の動画（それぞれ3件ずつ）
    // { uid: [], uid: [], ... }
    latestMovies: {},
    // 企画に紐づく動画一覧
    // getMoviesByPRIDで取得した場合のみ格納
    // { prid: [{}, ...], prid: [{}, ...], ... }
    moviesByPRID: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 取得済みの全ての動画
   */
  movies: state => state.movies,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} mvid 動画ID
   * @return {Object} 動画の一覧
   */
  movie: state => mvid => state.movies[mvid] || null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 最新の動画一覧
   */
  latestAllMovies: state => state.latestAllMovies,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 全動画を取得済みかどうか
   */
  isGotAllMovies: state => state.isGotAllMovies,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid ユーザーID
   * @return {Object} 指定ユーザーの最新の動画一覧
   */
  latestMovies: state => uid => state.latestMovies[uid] || [],
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} prid 企画ID
   * @return {Object[]} 取得済みの全ての動画
   */
  moviesByPRID: state => prid => state.moviesByPRID[prid] || null
}

const mutations = {
  /**
   * 動画情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} mvid 動画のドキュメントID
   * @param {Object} movie 動画情報
   */
  setMovie: (state, { mvid, movie }) => {
    state.movies = Object.assign({}, state.movies, { [mvid]: movie })
  },
  /**
   * 最新動画情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} movie 動画情報
   */
  pushLatestAllMovies: (state, movie) => {
    state.latestAllMovies.push(movie)
  },
  /**
   * 全動画情報の取得状況をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Boolean} isGotAllMovies 全ての動画を取得済みかどうか
   */
  setIsGotAllMovies: (state, isGotAllMovies) => {
    state.isGotAllMovies = isGotAllMovies
  },
  /**
   * ユーザーに紐づく最新動画情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid ユーザーID
   * @param {Object} movie 動画情報
   */
  pushLatestMovies: (state, { uid, movie }) => {
    // 初めての場合は空配列を追加
    if (!state.latestMovies[uid]) state.latestMovies[uid] = []
    state.latestMovies[uid].push(movie)

    // computedを反応させるために全体を更新
    state.latestMovies = Object.assign({}, state.latestMovies, { [uid]: state.latestMovies[uid] })
  },
  /**
   * 動画情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} prid 企画ID
   * @param {Object} movies 動画一覧
   */
  setMovieByPRID: (state, { prid, movies }) => {
    state.moviesByPRID = Object.assign({}, state.moviesByPRID, { [prid]: movies })
  },
  /**
     * stateのリセットを行う
     *
     * @param {Object} state 暗黙的に受け取るstate
     */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 動画情報の取得
   * @param {Strig} prid 企画ID
   * @param {Strig} mvid 動画ID
   */
  getMovie: async ({ commit }, { prid, mvid }) => {
    try {
      const doc = await firestore
        .collection('projects')
        .doc(prid)
        .collection('movies')
        .doc(mvid)
        .get()

      if (doc.exists) {
        const nowAt = new Date()
        const movie = Object.assign(doc.data(), { mvid: doc.id, prid: doc.ref.parent.parent.id })
        if (isPublishedMovie(movie, nowAt)) commit('setMovie', { mvid: mvid, movie: movie })
      }
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 指定ユーザーの最新動画情報を全て取得
   * @param {String} uid ユーザーID
   */
  getLatestAllMoviesByUid: async ({ commit }, uid) => {
    try {
      const snapshot = await firestore
        .collectionGroup('movies')
        .where('guests', 'array-contains', uid)
        .orderBy('startAt', 'desc')
        .get()

      const nowAt = new Date()
      snapshot.docs.forEach(doc => {
        const movie = Object.assign(doc.data(), { mvid: doc.id, prid: doc.ref.parent.parent.id })
        if (isPublishedMovie(movie, nowAt)) {
          commit('setMovie', { mvid: doc.id, movie: movie })
          commit('pushLatestMovies', { uid: uid, movie: movie })
        }
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 最新動画情報を取得
   * Footballcoach会員限定の場合もあるので、15件取得する
   * @param {Date} startAt 指定時刻より古い日付の動画を取得
   * @return {Object[]} 取得した動画一覧
   */
  getLatestAllMovies: async ({ commit }, startAt) => {
    try {
      const movies = []
      const nowAt = new Date()
      let startAfterDate = startAt

      // 公開済みの動画を10本以上取得する or これ以上動画が取得できなくなるまでループする
      while (movies.length < 10) {
        const snapshot = await firestore
          .collectionGroup('movies')
          .orderBy('startAt', 'desc')
          .startAfter(startAfterDate)
          .limit(15)
          .get()

        // 取得できる動画がないときはループを抜ける
        if (snapshot.size === 0) {
          commit('setIsGotAllMovies', true)
          break
        }

        // 公開日時を迎えた動画のみセットする
        snapshot.docs.forEach(doc => {
          const movie = Object.assign(doc.data(), { mvid: doc.id, prid: doc.ref.parent.parent.id })
          if (isPublishedMovie(movie, nowAt)) {
            commit('setMovie', { mvid: doc.id, movie: movie })
            commit('pushLatestAllMovies', movie)
            movies.push(movie)
          }
        })

        // 次のループに備え最後に取得した動画の作成日を記録する
        startAfterDate = snapshot.docs.slice(-1)[0].data().startAt.toDate()
      }
      return movies
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 企画IDに紐づく全動画情報の取得
   * @param {String} prid 企画ID
   */
  getMoviesByPRID: async ({ commit }, prid) => {
    try {
      // エピソード1から表示するため古い順で取得
      const snapshot = await firestore
        .collection('projects')
        .doc(prid)
        .collection('movies')
        .orderBy('startAt')
        .get()

      const movies = []
      const nowAt = new Date()
      snapshot.docs.forEach(doc => {
        const movie = Object.assign(doc.data(), { mvid: doc.id, prid: prid })
        if (isPublishedMovie(movie, nowAt)) {
          commit('setMovie', { mvid: doc.id, movie: movie })
          movies.push(movie)
        }
      })

      commit('setMovieByPRID', { prid: prid, movies: movies })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

/**
 * @param {Object} movie 動画
 * @param {Date} nowAt 現在時刻
 * @return {Boolean} 公開を開始している動画であるか
 */
const isPublishedMovie = (movie, nowAt) => !movie.startAt || (movie.startAt && movie.startAt.toDate() <= nowAt)

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

