<template>
  <div class="top" v-if="!isProcessing">
    <!-- 参加予定のイベント -->
    <template v-if="uid && displayedParticipationEventIDs.length > 0">
      <h2 class="top__title">参加予定のイベント</h2>
      <events-list class="top__element" :diplayedEventIDs="displayedParticipationEventIDs" />
    </template>
    <!-- 今後の開催イベント -->
    <template v-if="displayedPlanedEventIDs.length > 0">
      <h2 class="top__title">今後の開催イベント</h2>
      <events-list class="top__element" :diplayedEventIDs="displayedPlanedEventIDs" />
    </template>
    <!-- 新着コンテンツ -->
    <h2 class="top__title">新着コンテンツ</h2>
    <movie-vertical-list class="top__element" :movies="latestMovies" />
    <more-link class="top__more" position="right" text="すべての動画をみる" :to="{ name: 'movie_list' }" />
    <!-- おすすめコンテンツ -->
    <template v-if="hasRecommendMovies">
      <h2 class="top__title">おすすめコンテンツ</h2>
      <movie-list class="top__element" :movies="recommends.movies" />
    </template>
    <!-- おすすめ企画 -->
    <template v-if="hasRecommendProjects">
      <template v-for="(prid, index) in recommends.projects">
        <template v-if="project(prid) && recommendMovies(prid)">
          <h2 class="top__title" :key="prid + index + 'title'">{{ project(prid).name }}</h2>
          <movie-list class="top__element" :key="prid + index + 'element'" :movies="recommendMovies(prid)" />
        </template>
      </template>
    </template>
    <!-- 企画 -->
    <h2 class="top__title">企画一覧</h2>
    <project-list class="top__element" :projects="latestProjects" />
    <more-link class="top__more" position="right" text="すべての企画をみる" :to="{ name: 'project_list' }" />
  </div>
</template>

<script>
import MoreLink from '@/components/common/more'
import MovieVerticalList from '@/components/common/movie/list/vertical'
import projectList from '@/components/common/project'

import EventsList from '@/components/top/events'
import MovieList from '@/components/top/movies'

export default {
  name: 'top',
  components: { MoreLink, MovieVerticalList, projectList, EventsList, MovieList },
  async mounted () {
    await this.initSyncProcess()

    // 最新の動画情報の取得
    this.getLatestMoviesInfo()

    // おすすめ情報の取得
    await this.getRecommendsInfo()
    this.getRecommendMovies()
    this.getRecommendProjects()

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @param {String} url vimeoのurl
     * @return {Object} vimeoの動画情報
     */
    vimeo () {
      return url => {
        return this.$store.getters['vimeos/vimeo'](url)
      }
    },
    /**
     * @param {String} mvid 動画ID
     * @return {Object} 動画情報
     */
    movie () {
      return mvid => {
        return this.$store.getters['movies/movie'](mvid)
      }
    },
    /**
     * @param {String} prid 企画ID
     * @return {Object[]} 企画IDに紐づく動画情報の一覧
     */
    movies () {
      return prid => {
        return this.$store.getters['movies/moviesByPRID'](prid)
      }
    },
    /**
     * @param {String} prid 企画ID
     * @return {Object} 企画情報
     */
    project () {
      return prid => {
        return this.$store.getters['projects/project'](prid)
      }
    },
    /**
     * @return {Object[]} おすすめ動画一覧
     */
    recommends () {
      return this.$store.getters['recommends/recommends']
    },
    /**
     * @param {String} prid 企画ID
     * @return {Object[]} 企画IDに紐づく動画情報
     */
    recommendMovies () {
      return prid => {
        // 参照渡しを回避するためpushで代入
        const movies = []
        if (!!this.movies(prid)) this.movies(prid).forEach(movie => movies.push(movie))
        // moviesが存在しない場合はnullを返す
        if (!movies || movies.length === 0) {
          return null
        }
        // 新着順にソート
        movies.sort((a, b) => {
          return b.startAt - a.startAt
        })

        return movies.map(movie => {
          return { mvid: movie.mvid, prid: prid }
        })
      }
    },
    /**
     * @return {Boolean} おすすめ動画一覧が存在するかどうか
     */
    hasRecommendMovies () {
      const recommends = this.$store.getters['recommends/recommends']
      return recommends && recommends.movies && recommends.movies.length > 0
    },
    /**
     * @return {Boolean} おすすめ企画一覧が存在するかどうか
     */
    hasRecommendProjects () {
      const recommends = this.$store.getters['recommends/recommends']
      return recommends && recommends.projects && recommends.projects.length > 0
    },
    /**
     * @return {Object[]} 全動画一覧（最新順）
     */
    latestAllMovies () {
      return this.$store.getters['movies/latestAllMovies']
    },
    /**
     * @return {Object[]} 最新動画一覧（最新1件、非Footballcoach限定の場合はFootballcoach限定限定企画の動画は非表示）
     */
    latestMovies () {
      const displayNum = 1
      return this.latestAllMovies.filter(movie => this.uid || (this.project(movie.prid) && !this.project(movie.prid).isRestricted)).slice(0, displayNum)
    },
    /**
     * @return {Object[]} 全企画一覧のドキュメントID（最新順）
     */
    latestAllprojects () {
      return this.$store.getters['projects/latestProjects']
    },
    /**
     * @return {Object[]} 企画一覧のドキュメントID（最新8件、非Footballcoach限定の場合はFootballcoach限定限定企画は非表示）
     */
    latestProjects () {
      return this.$store.getters['projects/latestProjects'].filter(project => this.uid || !project.isRestricted).slice(0, 8)
    },
    /**
     * @return {String[]} 今後の開催されるイベントID一覧 (開催日時が近い順)
     */
    planedEventIDs () {
      return this.$store.getters['events/planedEventIDs']
    },
    /**
     * @return {String[]} ログインユーザーが参加した or 予定のイベントID一覧
     */
    participationEventIDs () {
      return this.$store.getters['participants/participationEventIDs']
    },
    /**
     * @return {String[]} 表示対象の今後開催されるイベントID
     */
    displayedPlanedEventIDs () {
      const planedEventIDs = this.planedEventIDs
      if (this.participationEventIDs.length <= 0) {
        return planedEventIDs.slice(0, 5)
      }
      // ログインユーザーが参加登録済みのものは取り除く
      return planedEventIDs.filter(evid => !this.participationEventIDs.includes(evid)).slice(0, 5)
    },
    /**
     * @return {String[]} ログインユーザーが参加予定のイベントID一覧(開催日時が近い順)
     */
    displayedParticipationEventIDs () {
      if (this.participationEventIDs.length <= 0) {
        return []
      }
      return this.planedEventIDs.filter(evid => this.participationEventIDs.includes(evid))
    }
  },
  methods: {
    /**
     * 初期処理（同期処理）
     */
    async initSyncProcess () {
      // 新着動画、企画、イベントの取得
      const promises = []
      const nowAt = new Date()
      if (!this.latestAllMovies.length) promises.push(this.$store.dispatch('movies/getLatestAllMovies', nowAt))
      if (!this.latestAllprojects.length) promises.push(this.$store.dispatch('projects/getLatestProjects', nowAt))
      if (!this.planedEventIDs.length) promises.push(this.$store.dispatch('events/getPlanedEvents', nowAt))
      if (!!(this.uid) && !this.participationEventIDs.length) promises.push(this.$store.dispatch('participants/getParticipationEventIDs', this.uid))
      await Promise.all(promises)
    },
    /**
     * 最新動画に紐づく情報の取得
     */
    async getLatestMoviesInfo () {
      // 最新動画に紐づくvimeoと企画情報を取得
      this.latestAllMovies.forEach(movie => {
        if (!this.vimeo(movie.movieURL)) this.$store.dispatch('vimeos/getVimeo', movie.movieURL)
        if (!this.project(movie.prid)) this.$store.dispatch('projects/getProject', movie.prid)
      })
    },
    /**
     * レコメンド情報の取得とそれに紐づく動画情報の取得
     */
    async getRecommendsInfo () {
      // レコメンド情報の取得
      await this.$store.dispatch('recommends/getRecommends')
      if (Object.keys(this.recommends).length === 0) return

      // レコメンドに紐づく企画情報を取得
      this.recommends.projects.forEach(prid => {
        if (!this.movies(prid)) this.$store.dispatch('movies/getMoviesByPRID', prid)
      })
    },
    /**
     * おすすめ情報に紐づく動画情報の取得
     */
    async getRecommendMovies () {
      // おすすめ情報がない場合は処理を終了
      if (Object.keys(this.recommends).length === 0) return

      // おすすめ情報に紐づく動画情報を取得
      const promises = []
      this.recommends.movies.forEach(ids => {
        if (!this.movie(ids.mvid)) promises.push(this.$store.dispatch('movies/getMovie', { prid: ids.prid, mvid: ids.mvid }))
      })
      await Promise.all(promises)

      // 動画情報に紐づくvimeo報を取得
      this.recommends.movies.forEach(ids => {
        const movie = this.movie(ids.mvid)
        if (movie && !this.vimeo(movie.movieURL)) this.$store.dispatch('vimeos/getVimeo', movie.movieURL)
      })
    },
    /**
     * おすすめ情報に紐づく企画情報の取得
     */
    async getRecommendProjects () {
      // おすすめ情報がない場合は処理を終了
      if (Object.keys(this.recommends).length === 0) return

      // おすすめ情報に紐づく動画情報を取得
      const promises = []
      this.recommends.projects.forEach(prid => {
        if (!this.movie(prid)) promises.push(this.$store.dispatch('movies/getMoviesByPRID', prid))
      })
      await Promise.all(promises)

      // 動画情報に紐づくvimeo報を取得
      this.recommends.projects.forEach(prid => {
        const movies = this.movies(prid)
        movies.forEach(movie => {
          if (movie && !this.vimeo(movie.movieURL)) this.$store.dispatch('vimeos/getVimeo', movie.movieURL)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.top {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width;
  margin: $header_height auto $footer_height auto;
  &__title {
    margin-top: $unit_size*6;
    font-size: 1.8rem;
    color: $black_normal_color;
    &:first-child {
      margin-top: 0;
    }
  }
  &__element {
    margin-top: $unit_size*2;
  }
  &__more {
    margin-top: $unit_size*4;
  }
}
</style>
