<template>
  <v-layout v-if="isDisplay" align-center justify-space-around class="footer">
    <div  :class="isPage('top') ? 'footer__menu--active' : 'footer__menu'" @click="$router.push({ name: 'top' })">
      <v-icon class="footer__menu__icon">home</v-icon>
      <div class="footer__menu__text">ホーム</div>
    </div>
    <div  :class="isPage('movie_list') ? 'footer__menu--active' : 'footer__menu'" @click="$router.push({ name: 'movie_list' })">
      <v-icon class="footer__menu__icon">play_circle</v-icon>
      <div class="footer__menu__text">動画</div>
    </div>
    <div  :class="isPage('event_list') ? 'footer__menu--active' : 'footer__menu'" @click="$router.push({ name: 'event_list' })">
      <v-icon class="footer__menu__icon">live_tv</v-icon>
      <div class="footer__menu__text">ライブ</div>
    </div>
    <div  :class="isPage('guest_list') ? 'footer__menu--active' : 'footer__menu'" @click="$router.push({ name: 'guest_list' })">
      <v-icon class="footer__menu__icon">group</v-icon>
      <div class="footer__menu__text">出演者</div>
    </div>
    <div  :class="isPage('save_list') ? 'footer__menu--active' : 'footer__menu'" @click="$router.push({ name: 'save_list' })">
      <v-icon class="footer__menu__icon">bookmark_border</v-icon>
      <div class="footer__menu__text">保存</div>
    </div>
  </v-layout>
</template>

<script>
const DYSPLAY_FOOTER = [
  'top',
  'movie_list',
  'event_list',
  'guest_list',
  'save_list'
]

export default {
  computed: {
    /**
     * @return {Boolean} フッターを表示させるかどうか
     */
    isDisplay () {
      return this.$route.name ? DYSPLAY_FOOTER.includes(this.$route.name) : false
    },
    /**
     * @param {String} page ページ名
     * @return {Booelan} 表示中のページが引数名のページかどうか
     */
    isPage () {
      return page => this.$route.name ? this.$route.name.indexOf(page) === 0 : false
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';
@import '@/assets/sass/fontfamily.scss';

.footer {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 100vw;
  max-width: $max_width;
  height: $footer_height;
  background-color: rgb(255 255 255 / 90%);
  transform: translate(-50%, 0);
  &__menu {
    &__icon {
      top: 14.58%;
      right: 8.33%;
      bottom: 14.58%;
      left: 8.33%;
      font-family: $material-outlined;
    }
    &__text {
      flex: none;
      flex-grow: 0;
      order: 1;
      width: 30px;
      height: 12px;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      color: $gray_normal_color;
      text-align: center;
    }
    &--active {
      @extend .footer__menu;
      .footer__menu__icon {
        &.theme--light.v-icon {
          color: $concept_normal_color;
        }
      }
      .footer__menu__text {
        color: $concept_normal_color;
      }
    }
  }
}
</style>
