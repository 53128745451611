import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 自分自身の例外情報
    exemption: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 自分自身の例外情報（ない場合はnull）
   */
  exemption: state => state.exemption
}

const mutations = {
  /**
   * 例外情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} exemption 例外情報（ない場合はnull）
   */
  setExemption: (state, exemption) => {
    state.exemption = exemption
    state.isGot = true
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 指定uidの例外情報を取得
   * @param {String} uid ユーザーID
   */
  getExemption: async ({ commit }, uid) => {
    try {
      const snapshot = await firestore
        .collection('exemptions')
        .where('uid', '==', uid)
        .get()
      const exemption = snapshot.size === 1 ? Object.assign(snapshot.docs[0].data(), { eid: snapshot.docs[0].id }) : null
      commit('setExemption', exemption)
      return exemption
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
