/**
 * original function library
 * [usage]
 * import resize from '@/assets/lib/resize'
 * mixins: [resize]
 */
import Vue from 'vue'

export default Vue.extend({
  destroyed () {
    window.removeEventListener('resize', this.setVh())
  },
  methods: {
    /**
     * リサイズ発生時にvhをセットするイベント作成
     */
    addSetVhEvent () {
      this.setVh()
      window.addEventListener('resize', this.setVh())
    },
    /**
     * vhのセット
     */
    setVh () {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
  }
})


