import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // ゲスト情報
    guests: [],
    // 全てのゲストを取得済みかどうか
    isGotAll: false
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String[]} ゲスト情報
   */
  guests: state => state.guests,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 全ゲストを取得済みかどうか
   */
  isGotAll: state => state.isGotAll
}

const mutations = {
  /**
   * ゲストのuidをstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} doc ゲスト情報
   */
  setGuest: (state, doc) => {
    state.guests = Object.assign({}, state.guests, { [doc.id]: doc.data() })
  },
  /**
   * 全ゲスト情報の取得状況をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Boolean} isGotAll 全てのゲストを取得済みかどうか
   */
  setIsGotAll: (state, isGotAll) => {
    state.isGotAll = isGotAll
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 表示用のゲストを取得
   * @param {Object} num 取得するゲストの数
   * @param {Object} createdAt 取得するゲストの作成日時
   */
  getGuestsAfterCreatedAt: async ({ commit }, { num, createdAt }) => {
    try {
      const snapshot = await firestore
        .collection('guests')
        .orderBy('createdAt', 'asc')
        .startAfter(createdAt)
        .limit(num)
        .get()

      if (snapshot.docs.length < num) {
        commit('setIsGotAll', true)
      }

      snapshot.docs.forEach(doc => {
        commit('setGuest', doc)
      })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
