/**
 * Vimeo API/SDKを利用して値の取得等を行っている
 * @see API https://developer.vimeo.com/api/oembed/videos
 * @see SDK https://developer.vimeo.com/player/sdk/reference
 */
import Player from '@vimeo/player'
import axios from 'axios'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 全vimeoの情報一覧
    // { url: {}, url: {}, ... }
    vimeos: {},
    // 現在再生中のプレイヤー
    player: null,
    // 現在の再生時間（秒）
    currentTime: 0.0
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} vimeoの情報
   */
  vimeos: state => state.vimeos,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} url vimeoのurl
   * @return {Object} vimeoの情報
   */
  vimeo: state => url => state.vimeos[url] ? state.vimeos[url] : null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Number} 現在の再生時間
   */
  currentTime: state => state.currentTime
}

const mutations = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} url vimeoのURL
   * @param {Object} vimeo vimeo情報
   */
  setVimeo: (state, { url, vimeo }) => {
    state.vimeos = Object.assign({}, state.vimeos, { [url]: vimeo })
  },
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} iframe 再生中のiframe情報
   */
  setPlayer: (state, iframe) => {
    if (!iframe) {
      state.player = null
      return
    }

    state.player = new Player(iframe)
  },
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Number} time 再生時間
   */
  setCurrentTime: (state, time) => {
    state.currentTime = time
  },
  /**
   * stateのリセットを行う
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * vimeoの情報を取得
   * @param {String} url vimeoのURL
   */
  getVimeo: async ({ commit }, url) => {
    try {
      // パラメータ
      const params = new URLSearchParams()
      params.append('url', url)
      params.append('maxwidth', 640)

      const response = await axios.get('https://vimeo.com/api/oembed.json?' + params)
      commit('setVimeo', { url: url, vimeo: response.data })
    } catch {
      // VimeoAPIがエラーを返却しても後続処理を続けるためなにもしない.
    }
  },
  /**
   * 再生時間のウォッチ
   * 再生時間が変わった場合は保存
   */
  onTimeUpdate: async ({ state, commit }) => {
    try {
      // もしプレイヤーがセットされてなければエラー
      if (!state.player) throw new Error('player is not found')
      state.player.on('timeupdate', data => {
        commit('setCurrentTime', data.seconds)
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 現在の再生時間をセットする
   * @param {Number} time 再生時間
   */
  setCurrentTime: async ({ state }, time) => {
    try {
      // もしプレイヤーがセットされてなければエラー
      if (!state.player) throw new Error('player is not found')

      // 指定時間に変更する
      await state.player.setCurrentTime(time)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 再生を開始させる
   */
  startPlay: async ({ state }) => {
    try {
      // もしプレイヤーがセットされてなければエラー
      if (!state.player) throw new Error('player is not found')

      await state.player.play()
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 再生を停止させる
   * @param {Number} time 停止後指定する指定時間
   */
  stopPlay: async ({ state }, time) => {
    try {
      // もしプレイヤーがセットされてなければエラー
      if (!state.player) throw new Error('player is not found')

      // 停止させて、指定時間に変更する
      await state.player.pause()
      await state.player.setCurrentTime(time)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * フルスクリーンの解除
   */
  exitFullscreen: async ({ state, commit }) => {
    try {
      // もしプレイヤーがセットされてなければエラー
      if (!state.player) return

      state.player.exitFullscreen()
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * プレイヤー情報のリセット
   */
  resetPlayer: async ({ state, commit }) => {
    try {
      // もしプレイヤーがセットされてなければエラー
      if (!state.player) return

      state.player.off('timeupdate')
      commit('setPlayer', null)
      commit('setCurrentTime', 0)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}


