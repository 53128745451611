import { firebase } from '@/firebase'
import router from '@/router'

// functionsがあるリージョン
const REGION = 'asia-northeast1'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  /**
   * イベントに対応したメール送信
   * @param {String} event イベント名
   * @see https://docs.google.com/spreadsheets/d/1VkLTGBv4xDddTbCn3vQ2SU3qQ7cLiE1tkAnfMVSmq7Y/edit#gid=0
   */
  sendEventEmail: async ({ commit }, event) => {
    try {
      const sendEventEmail = firebase.functions(REGION).httpsCallable('sendEventEmail')
      await sendEventEmail({ event: event })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * Stripeのカスタマー情報を削除
   */
  async deleteCustomer ({ commit }) {
    try {
      const deleteCustomer = firebase.functions(REGION).httpsCallable('deleteCustomer')
      deleteCustomer()
    } catch {
      router.push({ name: 'error' })
    }
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
