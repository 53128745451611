<template>
  <div class="project">
    <div class="project__element" v-for="project in projects" :key="project.prid">
      <router-link :to="{ name: 'project', params: { prid: project.prid } }">
        <v-img class="project__element__thumbnail" :src="project.image" :aspect-ratio="16/9" />
      </router-link>
      <section class="project__element__text">
        <h1 class="project__element__text__title">{{ project.name }}</h1>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 企画一覧
    projects: {
      type: Array,
      required: true
    }
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.project {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  &__element {
    width: 43%;
    margin-top: $unit_size * 3;
    &:nth-child(1) {
      margin-top: 0;
    }
    &:nth-child(2) {
      margin-top: 0;
    }
    &__thumbnail {
      width: 100%;
      height: auto;
      background: $gray_lighten_color;
      border-radius: 10px;
    }
    &__text {
      margin-top: $unit_size;
      &__title {
        margin-top: $unit_size;
        font-size: 1.3rem;
        font-weight: normal;
      }
    }
  }
}
</style>
