<template>
  <div class="top-events">
    <div class="top-events__element" v-for="evid in diplayedEventIDs" :key="evid">
      <router-link class="top-events__element__thumbnail" :to="{ name: 'event_detail', params: { evid: evid } }">
        <v-img class="top-events__element__thumbnail__img"
               :src="event(evid).thumbnail" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 表示されるイベントIDの一覧
    diplayedEventIDs: {
      type: Array,
      required: true
    }
  },
  computed: {
    /**
     * @param {String} evid イベントID
     * @return {Object} イベント情報
     */
    event () {
      return evid => {
        return this.$store.getters['events/event'](evid)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.top-events {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &__element {
    display: flex;
    flex-direction: column;
    align-items: top;
    margin-right: $unit_size*2;
    &:last-of-type {
      margin-right: 0;
    }
    &__thumbnail {
      display: inline-block;
      width: 200px;
      height: 105px;
      &__img {
        width: 100%;
        height: 100%;
        background: $gray_lighten_color;
      }
    }
  }
}
</style>

