import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 動画ごとの試聴時間
    // キーは「企画ID + アンダーバー + 動画ID」で格納（サブコレクションのためキーが重複する可能性があるため）
    // { prid_mvid: Object, prid_mvid: Object, ... }
    histories: {},
    // 最新の視聴履歴
    latestHistories: []
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} prid 企画ID
   * @param {String} mvid 動画ID
   * @return {Object} 該当動画の視聴履歴（存在しない場合はnull、取得してない場合はundefined）
   */
  history: state => ({ prid, mvid }) => state.histories[prid + '_' + mvid],
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} prid 企画ID
   * @return {Object[]} 該当企画の視聴履歴一覧
   */
  histories: state => prid => {
    const histories = []
    Object.keys(state.histories).forEach(key => {
      const index = key.indexOf(prid + '_')
      if (index !== -1 && state.histories[key]) histories.push(Object.assign(state.histories[key], { prid: prid, mvid: key.split('_')[1] }))
    })
    return histories
  },
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} 最新の視聴履歴
   */
  latestHistories: state => state.latestHistories
}

const mutations = {
  /**
   * 動画情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} prid 企画ID
   * @param {String} mvid 動画ID
   * @param {Object} history 視聴履歴
   */
  setHistory: (state, { prid, mvid, history }) => {
    const key = prid + '_' + mvid
    state.histories = Object.assign({}, state.histories, { [key]: history })
  },
  /**
   * 動画情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} prid 企画ID
   * @param {String} mvid 動画ID
   * @param {Object} params 変更情報
   */
  updateHistory: (state, { prid, mvid, params }) => {
    const key = prid + '_' + mvid
    const history = Object.assign(params, { createdAt: state.histories[key].createdAt })
    state.histories = Object.assign({}, state.histories, { [key]: history })
  },
  /**
   * 動画情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} prid 企画ID
   * @param {String} mvid 動画ID
   * @param {Object} history 視聴履歴
   */
  unshiftLatestHistory: (state, { prid, mvid, history }) => {
    // 既に存在する場合は削除
    state.latestHistories = state.latestHistories.filter(history => history.prid !== prid || history.mvid !== mvid)
    state.latestHistories.unshift(history)
  },
  /**
     * stateのリセットを行う
     *
     * @param {Object} state 暗黙的に受け取るstate
     */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 視聴履歴の取得
   * @param {String} prid 企画ID
   * @param {String} mvid 動画ID
   * @param {String} uid ユーザーID
   */
  getHistory: async ({ commit }, { prid, mvid, uid }) => {
    try {
      const doc = await firestore
        .collection('projects')
        .doc(prid)
        .collection('movies')
        .doc(mvid)
        .collection('browsingHistories')
        .doc(uid)
        .get()

      // 存在しない場合もnullを格納して取得を試みた旨が分かるようにする
      const history = doc.exists ? doc.data() : null
      commit('setHistory', { prid: prid, mvid: mvid, history: history ? Object.assign(history, { prid: prid, mvid: mvid }) : null })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 最新の視聴履歴の取得（最大8件）
   * @param {String} uid ユーザーID
   */
  getLatestHistories: async ({ commit }, uid) => {
    try {
      const snapshot = await firestore
        .collectionGroup('browsingHistories')
        .where('uid', '==', uid)
        .orderBy('updatedAt')
        .limit(8)
        .get()

      snapshot.docs.forEach(doc => {
        const prid = doc.ref.parent.parent.parent.parent.id
        const mvid = doc.ref.parent.parent.id
        const history = Object.assign(doc.data(), { prid: prid, mvid: mvid })
        commit('setHistory', { prid: prid, mvid: mvid, history: history })
        commit('unshiftLatestHistory', { prid: prid, mvid: mvid, history: history })
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 視聴履歴の追加
   * @param {String} prid 企画ID
   * @param {String} mvid 動画ID
   * @param {String} uid ユーザーID
   * @param {Number} time 視聴時間
   */
  setHistory: async ({ state, commit }, { prid, mvid, uid, time }) => {
    try {
      const history = { time: time, uid: uid, createdAt: new Date(), updatedAt: new Date() }
      await firestore
        .collection('projects')
        .doc(prid)
        .collection('movies')
        .doc(mvid)
        .collection('browsingHistories')
        .doc(uid)
        .set(history)

      commit('setHistory', { prid: prid, mvid: mvid, history: Object.assign(history, { prid: prid, mvid: mvid }) })
      if (state.latestHistories.length > 0) commit('unshiftLatestHistory', { prid: prid, mvid: mvid, history: Object.assign(history, { prid: prid, mvid: mvid }) })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 視聴履歴の更新
   * @param {String} prid 企画ID
   * @param {String} mvid 動画ID
   * @param {String} uid ユーザーID
   * @param {Number} time 視聴時間
   */
  updateHistory: async ({ commit }, { prid, mvid, uid, time }) => {
    try {
      const params = { time: time, uid: uid, updatedAt: new Date() }
      await firestore
        .collection('projects')
        .doc(prid)
        .collection('movies')
        .doc(mvid)
        .collection('browsingHistories')
        .doc(uid)
        .update(params)

      commit('updateHistory', { prid: prid, mvid: mvid, params: params })
      if (state.latestHistories.length > 0) commit('unshiftLatestHistory', { prid: prid, mvid: mvid, history: Object.assign(params, { prid: prid, mvid: mvid }) })
    } catch {
      router.push({ name: 'error' })
    }
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

