import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 全企画の一覧
    // { prid: {}, prid: {}, ... }
    projects: {},
    // 最新の企画
    latestProjects: [],
    // 全企画を取得済みかどうか
    isGotAllprojects: false
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} prid ドキュメントID
   * @return {Object} 企画情報
   */
  project: state => prid => state.projects[prid] || null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 最新の企画一覧
   */
  latestProjects: state => state.latestProjects,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 全企画を取得済みかどうか
   */
  isGotAllprojects: state => state.isGotAllprojects
}

const mutations = {
  /**
   * 企画情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} prid 企画ID
   * @param {Object} project 企画情報
   */
  setProject: (state, { prid, project }) => {
    state.projects = Object.assign({}, state.projects, { [prid]: project })
  },
  /**
   * 最新動画情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} project 企画情報
   */
  pushLatestProjects: (state, project) => {
    state.latestProjects.push(project)
  },
  /**
   * 全企画情報の取得状況をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Boolean} isGotAllProjects 全ての企画を取得済みかどうか
   */
  setIsGotAllProjects: (state, isGotAllProjects) => {
    state.isGotAllProjects = isGotAllProjects
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 企画情報を取得
   * @param {String} prid 企画ID
   */
  getProject: async ({ commit }, prid) => {
    try {
      const doc = await firestore.collection('projects').doc(prid).get()
      if (doc.exists) commit('setProject', { prid: doc.id, project: Object.assign(doc.data(), { prid: prid }) })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 最新企画情報を取得
   * Footballcoach会員限定の場合もあるので、20件取得する
   * @param {Date} startAt 指定時刻より古い日付の企画を取得
   * @return {Object[]} 取得した企画情報
   */
  getLatestProjects: async ({ commit }, startAt) => {
    try {
      const snapshot = await firestore
        .collection('projects')
        .orderBy('createdAt', 'desc')
        .startAfter(startAt)
        .limit(20)
        .get()

      const projects = []
      snapshot.docs.forEach(doc => {
        const project = Object.assign(doc.data(), { prid: doc.id })
        commit('setProject', { prid: doc.id, project: project })
        commit('pushLatestProjects', project)
        projects.push(project)
      })

      if (snapshot.size === 0) commit('setIsGotAllProjects', true)
      return projects
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
