import Vue from 'vue'
import VueSanitize from 'vue-sanitize'
import VueClipboard from 'vue-clipboard2'
import VueGtm from '@gtm-support/vue2-gtm'

import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'

import '@babel/polyfill'
import 'whatwg-fetch'

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV !== 'production'

/**
 * setting of vue sanitize
 * @see https://github.com/daichirata/vue-sanitize
 */
Vue.use(VueSanitize)

/**
 * @see https://github.com/Inndy/vue-clipboard2
 */
Vue.use(VueClipboard)

/**
 * setting of gtm
 * @see https://github.com/gtm-support/vue-gtm/tree/vue2-gtm
 */
const gTMIDs = process.env.VUE_APP_GTM_ID ? process.env.VUE_APP_GTM_ID.split(',') : []
const enableGTM = gTMIDs.length > 0
if (enableGTM) {
  Vue.use(VueGtm, { id: gTMIDs.length > 1 ? gTMIDs : gTMIDs[0] })
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
