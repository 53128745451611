import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 一覧を取得済みかどうか
    isGotAll: false,
    // 保存済み動画一覧
    // [{ smid: , ... }, ... ]
    saveMovies: []
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 一覧を取得済みかどうか
   */
  isGotAll: state => state.isGotAll,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid ユーザーID
   * @param {String} prid 企画ID
   * @param {String} mvid 動画ID
   * @return {String} 保存済み動画情報のID
   */
  smid: state => ({ prid, mvid }) => {
    const saveMovie = state.saveMovies.find(saveMovie => saveMovie.prid === prid && saveMovie.mvid === mvid)
    return saveMovie ? saveMovie.smid : null
  },
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} 保存済み動画一覧
   */
  saveMovies: state => state.saveMovies
}

const mutations = {
  /**
   * 動画保存情報のセット
   *
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Boolean} isGotAll 一覧を取得済みかどうか
   */
  setIsGotAll: (state, isGotAll) => {
    state.isGotAll = isGotAll
  },
  /**
   * 動画保存情報のセット
   *
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} data 動画の保存情報
   */
  setSaveMovie: (state, data) => {
    state.saveMovies.push(data)
    // 新着順にソート
    state.saveMovies.sort((a, b) => {
      return b.createdAt - a.createdAt
    })
  },
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} smid 動画の保存情報のID
   */
  deleteSaveMovie: (state, smid) => {
    state.saveMovies = state.saveMovies.filter(saveMovie => saveMovie.smid !== smid)
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 保存済み動画の取得
   * @param {String} uid ユーザーID
   * @param {String} prid 企画ID
   * @param {String} mvid 動画ID
   */
  getSaveMovie: async ({ commit }, { uid, prid, mvid }) => {
    try {
      const snapshot = await firestore
        .collection('save_movie')
        .where('uid', '==', uid)
        .where('prid', '==', prid)
        .where('mvid', '==', mvid)
        .get()

      snapshot.docs.forEach(doc => {
        commit('setSaveMovie', Object.assign({ smid: doc.id }, doc.data()))
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 全ての保存済み動画の取得
   * @param {String} uid ユーザーID
   */
  getSaveMovies: async ({ commit }, uid) => {
    try {
      const snapshot = await firestore
        .collection('save_movie')
        .where('uid', '==', uid)
        .get()

      snapshot.docs.forEach(doc => {
        commit('setSaveMovie', Object.assign({ smid: doc.id }, doc.data()))
      })

      commit('setIsGotAll', true)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 動画の保存
   * @param {Object} params 保存動画情報
   */
  addSaveMovie: async ({ commit }, params) => {
    try {
      const res = await firestore
        .collection('save_movie')
        .add(params)

      commit('setSaveMovie', Object.assign({ smid: res.id }, params))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 動画の保存を解除
   * @param {String} smid 保存動画情報のID
   */
  deleteSaveMovie: async ({ commit }, smid) => {
    try {
      await firestore
        .collection('save_movie')
        .doc(smid)
        .delete()

      commit('deleteSaveMovie', smid)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
