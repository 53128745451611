<template>
  <div class="processing">
    <v-progress-circular indeterminate :width="5" :size="50" />
  </div>
</template>

<script>
import resize from '@/assets/lib/resize'

export default {
  mixins: [resize],
  mounted () {
    // スマホのときはアドレスバー分高さがずれるので、vhを定義し直す
    if (this.isSP) this.addSetVhEvent()
  },
  computed: {
    /**
     * @return {Boolean} SPデバイスかどうか
     */
    isSP () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.processing {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $processing_zindex;
  display: flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background-color: $white_normal_color;
  .v-progress-circular {
    color: $concept_normal_color;
  }
}
</style>
