import Vue from 'vue'
import Router from 'vue-router'
import Top from '@/views/Top.vue'

import store from '@/store'
import { firebase } from '@/firebase'

/**
 * vue-router v3.1.0から、同一画面に遷移するとコンソールエラーが発生するようになった
 * push関数にエラーハンドリングを追加して、デフォルトの関数を上書きすることで対応
 * @see https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
 */
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'top',
      component: Top
    },
    {
      path: '/signup',
      name: 'signup',
      component: loadComponent('Signup.vue')
    },
    {
      path: '/signup/card',
      name: 'signup_card',
      component: loadComponent('SignupCard.vue')
    },
    {
      path: '/event/list',
      name: 'event_list',
      component: loadComponent('EventList.vue')
    },
    {
      path: '/event/:evid',
      name: 'event_detail',
      component: loadComponent('EventDetail.vue')
    },
    {
      path: '/movie/list',
      name: 'movie_list',
      component: loadComponent('MovieList.vue')
    },
    // ローカル用.firebase-hostingでfunctionsに飛ぶ.
    {
      path: '/movie/:prid/:mvid',
      name: 'movie',
      component: loadComponent('Movie.vue')
    },
    // ogpリダイレクト用
    {
      path: '/movie/ogp/:prid/:mvid',
      name: 'movie_ogp',
      component: loadComponent('Movie.vue')
    },
    {
      path: '/project/list',
      name: 'project_list',
      component: loadComponent('ProjectList.vue')
    },
    // ローカル用.firebase-hostingでfunctionsに飛ぶ.
    {
      path: '/project/:prid',
      name: 'project',
      component: loadComponent('Project.vue')
    },
    // ogpリダイレクト用
    {
      path: '/project/ogp/:prid',
      name: 'project_ogp',
      component: loadComponent('Project.vue')
    },
    {
      path: '/save/list',
      name: 'save_list',
      component: loadComponent('SaveList.vue')
    },
    {
      path: '/guest/list',
      name: 'guest_list',
      component: loadComponent('GuestList.vue')
    },
    {
      path: '/profile/:uid',
      name: 'profile',
      component: loadComponent('Profile.vue')
    },
    {
      path: '/payment/card',
      name: 'payment_card',
      component: loadComponent('PaymentCard.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/menu',
      name: 'menu',
      component: loadComponent('Menu.vue')
    },
    {
      path: '/login/:provider',
      name: 'login',
      component: loadComponent('Login.vue')
    },
    {
      path: '/cancel',
      name: 'cancel',
      component: loadComponent('Cancel.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/terms',
      name: 'terms',
      component: loadComponent('Terms.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/policy',
      name: 'policy',
      component: loadComponent('Policy.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/law',
      name: 'law',
      component: loadComponent('Law.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/error',
      name: 'error',
      component: loadComponent('Error.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/notfound',
      name: 'notfound',
      component: loadComponent('NotFound.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/notsupported',
      name: 'notsupported',
      component: loadComponent('NotSupported.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '**',
      redirect: { name: 'notfound' }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

/**
 * viewsのファイルをロード
 * @param {String} name viewsのファイル名
 * @return {Object} 遅延ロードしたコンポーネント
 */
function loadComponent (name) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${name}`)
}

/**
 * ページ遷移の分岐処理
 */
router.beforeEach((to, from, next) => {
  const isNotSupportBrowser = to.name !== 'notsupported' && !store.getters.isSupportBrowser
  const autoTransition = to.matched.some(record => record.meta.autoTransition)
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  // 初回アクセスのとき or サポート対象外のブラウザのときにチェックを行う
  if (isNotSupportBrowser) analysisBrowser(to)

  // 別画面に遷移する時、processingを表示状態にする
  if (to.name !== from.name) store.commit('setProcessing', true)
  // 同一画面に遷移する時、processingを非表示状態にする
  if (to.name == from.name) store.commit('setProcessing', false)

  // 認証後の遷移先として現在のURLを保存する
  const redirectURL = store.getters.redirectPath
  if (!redirectURL) store.commit('setRedirectURL', to.path)

  // 判定用のデータ取得
  const auth = firebase.auth().currentUser
  const uid = auth ? auth.uid : null
  const user = uid ? store.getters['users/user'](uid) : null
  const exemption = uid ? store.getters['exemptions/exemption'] : null
  const card = store.getters['cards/card']
  const payment = store.getters['payments/payment']
  const subscription = store.getters['subscriptions/subscription']
  const isAuthProcessing = store.getters.isAuthProcessing

  // 遷移条件
  // ログイン済みかつユーザー情報が登録されており、例外ユーザーでなくカード情報が無い場合
  const isSignupCard = !!user && !exemption && !card
  // 決済が失敗している場合
  const isFailedPayment = (!!payment && payment.status === 'cardError') || (!!subscription && ['incomplete', 'incomplete_expired', 'past_due', 'unpaid'].includes(subscription.status))

  // LINEで外部ブラウザ遷移用パラメータがなければ付与した上で次の処理に飛ばす
  if (!to.query.openExternalBrowser) {
    next({ name: to.name, params: to.params, query: Object.assign({ openExternalBrowser: 1 }, to.query) })
  }

  // 認証、ユーザー取得状況に応じてページ遷移
  // 初回アクセス時、onAuthの処理が終わる前にrouterが動くため、初回処理が終わるまで遷移をループさせる
  if (isAuthProcessing || autoTransition) {
    if (autoTransition) {
      next()
    } else {
      // Auth処理中の場合はパラメータ情報が失われないようstoreに保存し遷移をキャンセルさせる
      store.commit('setQueryParameter', location.search)
      next(false)
    }
  } else if (!uid) {
    // 未ログインの場合
    requiresAuth ? next({ name: 'top' }) : next()
  } else if (isSignupCard) {
    // ログイン済みかつ通常ユーザーでまだカードが未登録の場合
    to.name === 'signup_card' ? next() : next({ name: 'signup_card' })
  } else if (isFailedPayment) {
    // 決済が失敗している場合カード変更画面へ遷移させる
    to.name === 'payment_card' ? next() : next({ name: 'payment_card' })
  } else if (['signup', 'signup_card'].includes(to.name)) {
    // ログイン済みかつ加入済みで不要な画面に遷移しようとした場合ホームへ遷移させる
    next({ name: 'top' })
  } else {
    next()
  }
})

export default router

/**
 * ブラウザの解析をする
 * androidのWebViewのときは閲覧しようとしたページをchromeで開く
 * @see "https://docs.google.com/document/d/1WITn598mniIV7k2ZmBJT8wLO6vOhI41tXp8GC-Qihg0"
 *
 * @param {Object} to 遷移先ページ
 */
const analysisBrowser = to => {
  const userAgent = parseUserAgent()
  store.commit('setSupportBrowser', userAgent.isSupportBrowser)

  if (userAgent.isAndroidWebView) {
    openAndroidChrome(to.path)
  } else {
    // なにもしない.
    // ただし、iOSのWebViewユーザーはログインを行った場合に処理を実施する
  }
}

/**
 * アクセスされたUserAgentを解析する
 *
 * @return {Object} UserAgentをパースした結果 { isAndroidWebView: andoroidのWebViewか, isIOSWebView: iOSのWebViewか, isSupportBrowser: サポートブラウザか}
 */
const parseUserAgent = () => {
  const rowUa = window.navigator.userAgent || ''
  const ua = rowUa.toLowerCase().trim()

  const isAndroid = /android/.test(ua)
  const isIOS = /iphone|ipod|ipad/.test(ua)
  const isWebView = /fb_iab|fban|messenger|line\/|yjapp/.test(ua)

  const isAndroidWebView = isAndroid && isWebView
  const isIOSWebView = isIOS && isWebView

  return {
    isAndroidWebView: isAndroidWebView,
    isIOSWebView: isIOSWebView,
    isSupportBrowser: !(isIOSWebView || isAndroidWebView)
  }
}

/**
 * 閲覧しようとしたページをandroidのchromeで表示する
 *
 * @param {String} path 閲覧しようとしたページのパス
 */
const openAndroidChrome = path => {
  const hostPath = location.host + path
  window.location.href = 'intent://' + hostPath + '/#Intent;scheme=https;action=android.intent.action.VIEW;package=com.android.chrome;end'
}
