import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 参加した(予定)イベントID一覧
    participationEventIDs: []
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String[]} 参加した(予定)イベントID一覧
   */
  participationEventIDs: state => state.participationEventIDs
}

const mutations = {
  /**
   * 参加した(予定)イベントID一覧をセットする
   *
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String[]} participationEventIDs 参加した(予定)イベントID一覧
   */
  setParticipationEventIDs: (state, participationEventIDs) => {
    state.participationEventIDs = participationEventIDs
  },
  /**
   * 参加した(予定)イベントID一覧に新しいIDを追加する
   *
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} evid イベントID
   */
  addParticipationEventID: (state, evid) => {
    state.participationEventIDs.push(evid)
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 参加した(予定)イベントID一覧を取得する
   * @param {String} uid ログインユーザーのID
   */
  getParticipationEventIDs: async ({ commit }, uid) => {
    try {
      const snapshot = await firestore
        .collectionGroup('participants')
        .where('uid', '==', uid)
        .get()

      if (!snapshot.empty) {
        const participationEventIDs = []
        snapshot.docs.forEach(doc => {
          participationEventIDs.push(doc.ref.parent.parent.id)
        })
        commit('setParticipationEventIDs', participationEventIDs)
      }
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * イベントに参加する
   *
   * @param {String} evid イベントID
   * @param {String} uid 参加するユーザーのID
   */
  participateEvent: async ({ commit }, { evid, uid }) => {
    try {
      await firestore
        .collection('events')
        .doc(evid)
        .collection('participants')
        .doc(uid)
        .set({ uid: uid, createdAt: new Date() })

      commit('addParticipationEventID', evid)
    } catch {
      router.push({ name: 'error' })
    }
  }
}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
